<template>
  <div id="addPackage" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5 mb-n10"
        >EDITAR PAQUETE DE PRESTACIONES</v-row
      >
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <!--EMPRESA-->
            <v-row>
              <label for="calendar">Empresa</label>
              <select
                name="empresa"
                id="empresa"
                v-model="paquete.empresaId"
                disabled
              >
                <option disabled selected value="0">Selecciona empresa</option>
                <option
                  v-for="enterprise in enterprises"
                  :key="enterprise.id"
                  v-bind:value="enterprise.id"
                >
                  {{ enterprise.razonSocial }}
                </option>
              </select>
            </v-row>
            <!--NOMBRE DEL PAQUETE-->
            <v-row>
              <label for="nombrePaquete">Nombre del Paquete</label>
              <input
                type="text"
                name="nombrePaquete"
                id="nombrePaquete"
                class="inputs"
                autocomplete="false"
                placeholder="Nombre del paquete..."
                v-model="paquete.nombrePaquete"
                maxlength="50"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
            <!--PRESTACIONES-->
            <v-row class="mt-2">
              <label for="prestacion">Prestaciones</label>
              <multiselect
                v-model="paquete.prestaciones"
                trackBy="id"
                label="nombrePrestacion"
                @input="prestacionesAuxiliares"
                placeholder="Seleccione prestacion(es)"
                tag-placeholder="Agregar prestación"
                selectLabel="Enter para agregar"
                deselectLabel="Enter para eliminar"
                selectedLabel="Seleccionado"
                :multiple="true"
                :options="prestaciones"
                class="inputs"
                style="width: 170px !important"
              >
                <span slot="noResult"
                  >No se encontraron elementos con la búsqueda</span
                >
                <span slot="noOptions">No existen elementos</span>
              </multiselect>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/packages" id="backCalendar">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Notificaciones</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de modificar el Paquete de prestaciones?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Agregar Notificación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="back()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      enterprise: 0,
      enterprises: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      nombrePaquete: "",
      paquete: {
        id: 1,
        enterprise: 1,
        nombrePaquete: "Paquete 1",
        prestaciones: [
          {
            id: 1,
            nombrePrestacion: "prestacion 1",
          },
        ],
      },
      prestaciones: [
        {
          id: 1,
          nombrePrestacion: "prestacion 1",
          tipo: 1,
          sdi: 2,
          aplica: 1,
          monto: 500,
          porcentaje: 0,
        },
        {
          id: 2,
          nombrePrestacion: "prestacion 2",
          tipo: 2,
          sdi: 2,
          aplica: 2,
          monto: 0,
          porcentaje: 70,
        },
      ],
      prestacionesSeleccionadas: [],
      prestacionesAux: [],
      prestaciones: [],
    };
  },
  methods: {
    back() {
      this.confirmation = false;
      this.$router.push("/packages");
    },
    prestacionesAuxiliares() {
      this.prestacionesAux = [];
      this.paquete.prestaciones.forEach((prestacion) =>
        this.prestacionesAux.push({ PrestacionId: prestacion.id })
      );
      //console.log("Aux que se envía", this.prestacionesAux);
    },
    guardar() {
      this.advice = false;
      this.paquete.prestaciones = this.prestacionesAux;
      this.show = true;
      axios
        .put(
          Server + "/paquetesPrestaciones/" + this.paquete.id,
          this.paquete,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          localStorage.paqueteId = 0;
          this.paquete = [];
          this.prestacionessAux = [];
          this.respuesta = "El Paquete fue actualizado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            localStorage.paqueteId = 0;
            this.paquete = [];
            this.prestacionesAux = [];
            this.respuesta = "El paquete no fue actualizado con éxito";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      //console.log("nuevas prestaciones", this.prestacionesAux);
      if (this.paquete.enterprise == " " || this.paquete.enterprise == 0) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (
        this.paquete.nombrePaquete == " " ||
        this.paquete.nombrePaquete == ""
      ) {
        this.errors.push(
          "Se debe especificar el nombre del paquete de prestaciones."
        );
      }
      if (this.prestacionesAux.length < 2) {
        this.errors.push("Se debe especificar al menos dos prestaciones.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      localStorage.paqueteId = "";
      this.$router.push("/packages");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/paquetesPrestaciones/" + localStorage.packageId, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("paquete");
          this.paquete = response.data;
          if (this.paquete.prestaciones.length > 0) {
            this.paquete.prestaciones.forEach((element, index) => {
              this.prestacionesAux.push({ PrestacionId: element.prestacionId });
            });
          }
          //console.log("prestaciones ya seleccionadas:", this.prestacionesAux);
          this.obtenerPrestaciones();
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    recuperar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerPrestaciones() {
      this.show = true;
      axios
        .get(Server + "/prestaciones/por-empresa/" + this.paquete.empresaId, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.prestaciones = response.data;
          var prestacionAuxiliar = [];
          this.prestaciones.forEach((element) => {
            this.paquete.prestaciones.forEach((elementoDos) => {
              if (element.id == elementoDos.prestacionId) {
                prestacionAuxiliar.push(element);
              }
            });
          });
          this.paquete.prestaciones = prestacionAuxiliar;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.recuperar();
  },
};
</script>
<style>
.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}
.multiselect__option.multiselect__option--highlight {
  background: rgb(250, 181, 52) !important;
}
.multiselect__tag {
  background: rgba(253, 175, 29, 0.856) !important;
} /*
.multiselect__option--highlight:after{
  
  background: rgba(236, 154, 0, 0.856) !important;
}*/
</style>